import { Result, Typography } from 'antd';
import React from 'react';
import PlumLogo from '../../../assets/images/b4blogo.jpeg';
import { i18n } from '../../../common';

const { Title, Text } = Typography;

export const GoToSubdomainPage = () => (
  <Result
    icon={<img width={64} height={64} src={PlumLogo} alt="" />}
    title={<Title style={{}}>
      PLUM CRM
    </Title>}
    subTitle={<Text style={{}}>
      {i18n.translate(`pages.goToSubdomain.subtitle`)}
    </Text>}
  />
)





