import { Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sider from 'antd/es/layout/Sider';
import { ContentComponent } from '../../../components';
import { colors } from '../../../theme';
import TextImage from '../../../assets/images/text.png';

const AuthRouter = () => (
  <Layout style={{ minHeight: '100vh' }} hasSider>
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: colors.redPrimary
      }}
      width={'35%'}
      breakpoint="xl"
      collapsedWidth="0"
    >
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img width={400} src={TextImage} alt="" />
      </div>
    </Sider>
    <Layout id="auth-container">
      <ContentComponent backgroundCol={colors.white} className='login-layout'>
        <Outlet />
      </ContentComponent>
    </Layout>
  </Layout>
)

export default AuthRouter;
