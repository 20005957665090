import React from 'react';
import { Typography, Row, Space, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordInterface, forgotPasswordAction, i18n, useAppDispatch } from '../../../common';
import PlumLogo from '../../../assets/images/b4blogo.jpeg';
import ForgotPasswordForm from '../../../components/forms/forgotPasswordForm/forgotPasswordForm.component';

const { useToken } = theme;

const { Title } = Typography;

const ForgotPasswordPage = () => {
  const { token } = useToken();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onForgotPassword = (values: any) => {
    const forgotPasswordData: ForgotPasswordInterface = {
      email: values.email,
    };
    dispatch(forgotPasswordAction(forgotPasswordData, navigate));
  };

  const onForgotPasswordFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="forgotPassword">
      <Row justify='center'>
        <Row>
          <Space size={16} align='center'>
            <img width={33} height={37} src={PlumLogo} alt="" />
            <Title className='main-title' style={{ color: token.colorPrimary }}>{i18n.translate(`login.top.title`)}</Title>
          </Space>
        </Row>
      </Row>
      <ForgotPasswordForm onForgotPasswordFormFinish={(values) => onForgotPassword(values)} onForgotPasswordFormFinishFailed={(errorInfo) => onForgotPasswordFailed(errorInfo)}></ForgotPasswordForm>
    </div>
  );
}

export default ForgotPasswordPage;
